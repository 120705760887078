import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { useState } from "react";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { editVariance } from "../../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";

type IncidentTimeProps = {
  canEdit: boolean;
};

function IncidentTime({ canEdit }: IncidentTimeProps) {
  const dispatch = useAppDispatch();
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [incidentEdit, setIncidentEdit] = useState(false);

  const convertToDateTimeLocalString = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const startingDate = (): Date => {
    let sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    return sixMonthsAgo;
  };

  const [incidentDate, setIncidentDate] = useState<string>(
    incident.incidentDate
      ? convertToDateTimeLocalString(new Date(incident.incidentDate))
      : convertToDateTimeLocalString(startingDate())
  );

  return (
    <Box>
      {(incident.incidentDate === null && !incidentEdit) ? (
        <Stack direction="row">
        <TextField
          sx={{ width: 300 }}
          id="outlined-read-only-input"
          value="When did the incident occur?"
          InputProps={{
            readOnly: true,
          }}
        />
        <IconButton
            disabled={!canEdit}
            size="small"
            onClick={() => setIncidentEdit(!incidentEdit)}
          >
            <ModeEditOutlinedIcon fontSize="small" />
          </IconButton>
        </Stack>
      ) : !incidentEdit ? (
        <Stack direction="row">
          <Typography variant="h6" className="psn-green">
            Incident Time: &nbsp;
          </Typography>
          <Typography variant="h6" className="psn-green">
            {new Date(incidentDate).toLocaleString()}
          </Typography>
          <IconButton
            disabled={!canEdit}
            size="small"
            onClick={() => setIncidentEdit(!incidentEdit)}
          >
            <ModeEditOutlinedIcon fontSize="small" />
          </IconButton>
        </Stack>
      ) : (
        <Stack direction="row">
          <TextField
            id="incident-time-input"
            type="datetime-local"
            value={incidentDate}
            onChange={(e) => {
              setIncidentDate(e.target.value);
              dispatch(
                editVariance({
                  name: "incidentDate",
                  value: e.target.value,
                })
              );
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <IconButton
            size="small"
            onClick={() => { setIncidentEdit(!incidentEdit); dispatch(editVariance({ name: "incidentDate", value: new Date(incidentDate) })); }}
          >
            <CheckCircleOutlineOutlinedIcon fontSize="small" />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
}

export { IncidentTime };
