import { Button, Dialog, DialogContent, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react"
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../app/hooks"
import { toggleCodeLookup, toggleDrgGroupMatching } from "../../../../../app/slices/VerificationModuleSlice";
import { PayerForCenterRequest, ProcedureLookupRequest } from "../../../../../services/verifyService/commands";
import { orgApi, useCenterFindQuery, usePayerDetailsQuery, useProcedureLookupQuery } from "../../../../../services/orgService/org-api";
import { ApptNote } from "./ApptNote";
import { CodeLookupResults } from "./CodeLookupResults";
import { DrgGroupMatching } from "./drgGroupMatching/DrgGroupMatching";
import { evaluateDrgCombo } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { useParams } from "react-router";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { OutPatientLCodeMatching } from "./lCodeMatching/OutPatientLCodeMatching";

function CodeLookup(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);    
    const verification = useAppSelector((state) => state.verification.verification);
    const primaryPayer = schedule.data?.insurances.find(p => p.priority === 1)
    const calctype = usePayerDetailsQuery(new PayerForCenterRequest(verification.center, primaryPayer?.payerCode??'')).data?.financialClass;
    const center = useCenterFindQuery(id!.split("-")[0]);    
    const dispatch = useDispatch();
    const isOpen = useAppSelector((state) => state.verificationModule.CodeLookupModal);
    const [searchTerm,setSearchTerm] = useState('');
    const [skip, setSkip] = React.useState(true);
    const {data} = useProcedureLookupQuery(new ProcedureLookupRequest(verification.center,searchTerm), {skip,});
    const handleClose = () => {
        setSkip(true);
        dispatch(toggleCodeLookup());
        setSearchTerm('');
        dispatch(evaluateDrgCombo(verification.procedures));
        dispatch(toggleDrgGroupMatching());
    };

    return <React.Fragment>
        <Dialog open={isOpen} maxWidth='xl'>
            <DialogContent>
                <ApptNote/>
                <Stack direction='row' gap={1}>
                    <TextField value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value); setSkip(true)}} size='small'
                        onKeyDown={(e)=>{if(e.key==='Enter') setSkip(false)}}
                        InputLabelProps={{shrink:false}} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}/>
                    <Button variant='outlined' disabled={searchTerm===''} onClick={()=>{dispatch(orgApi.util.invalidateTags(['lookup'])); setSkip(false);}}>Search</Button>
                </Stack>
                <Stack direction='row'>
                    <CodeLookupResults procedures={data === undefined ? [] : data}/>
                </Stack>
                <Stack direction='row'justifyContent='flex-end' marginTop='10px'>
                    <Button variant='outlined' onClick={()=>{handleClose()}}>Close</Button>
                </Stack>
            </DialogContent>
        </Dialog>
        {schedule.data?.admissionType === "IP" &&
        <DrgGroupMatching/>
        }
        {schedule.data?.admissionType === "OP" && center.data?.centerType =='ASC' &&
        (calctype === 'BCBS BAV' ||
        calctype === 'BCBS PPO' ||
        calctype === 'BCBS Trad' ||
        calctype === 'BCBS Blue Essential' ||
        calctype === 'BCBS Blue Premier HPN') &&
        <OutPatientLCodeMatching/>
        }
    </React.Fragment>
}

export {CodeLookup}