import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Stack } from "@mui/material";
import { toggleOutPatientLCodeMatching } from "../../../../../../app/slices/VerificationModuleSlice";
import { resetCarveOutCombo } from "../../../../../../services/verifyService/model/verification/VerificationSlice";
import { ApplyCarveOutCodes } from "./ApplyCarveOutCodes";

function OutPatientLCodeMatching() {
    const isOpen = useAppSelector(state => state.verificationModule.OutPatientLCodeMatchingModal);
    var dispatch = useAppDispatch();    
    const carveOutCombo = useAppSelector(
        (state) => state.verification.verification.carveOutCombos
    );
    useEffect(() => {
        if (carveOutCombo && carveOutCombo.length > 0) {
            dispatch(toggleOutPatientLCodeMatching());
        }
    }, [carveOutCombo, dispatch]);
    const close = () => {
        dispatch(toggleOutPatientLCodeMatching());
        dispatch(resetCarveOutCombo());
    };
    
  return (
   <React.Fragment>
       <Dialog open={isOpen}>
           <DialogTitle>Outpatient L Code Match</DialogTitle>
           <DialogContent>
               <DialogContentText>
                    <Stack direction='column' spacing={1}>
                        <strong>Matched On:</strong> {carveOutCombo?.[0]?.CPTMatch}
                        <strong>Adding codes on match:</strong> {
                            carveOutCombo?.map((combo, index) => {
                                return <React.Fragment key={index}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Code</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{combo.Code}</td>
                                                <td>{combo.TotalCodeCount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            }
                            )
                        }
                    </Stack>
               </DialogContentText>
           </DialogContent>
           <DialogActions>
               <Button variant='outlined' onClick={()=>{close()}}>Cancel</Button>
               <ApplyCarveOutCodes/>
           </DialogActions>
       </Dialog>
    </React.Fragment>
  );
}
export {OutPatientLCodeMatching}