import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { VarianceStartCommand } from "../../../../../services/varianceService/commands";
import { useStartPatientVarianceMutation } from "../../../../../services/varianceService/variance-api";
import { GridButton } from "../../../../../components";
import { useAppDispatch } from "../../../../../app/hooks";
import { useNavigate } from "react-router";
import { setVarianceIndividual } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";
import { DefaultVarianceRequest } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequest";
import { toggleInitiatePatientSearchDialog } from "../../../../../app/slices/VarianceModuleSlice";

function VarianceInitiateCommand(props: GridCellProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const varianceStart = DefaultVarianceRequest();
  varianceStart.center = props.dataItem.center;
  varianceStart.individual.mrn = props.dataItem.mrn;
  varianceStart.individual.firstName = props.dataItem.firstName;
  varianceStart.individual.lastName = props.dataItem.lastName;
  varianceStart.individual.age = props.dataItem.age;
  varianceStart.individual.sex = props.dataItem.sex;
  varianceStart.individual.homePhone = props.dataItem.homePhone;
  varianceStart.individual.mobilePhone = props.dataItem.mobilePhone;
  varianceStart.individual.email = props.dataItem.email;
  varianceStart.individual.individualType = "Patient";

  const [initiate] = useStartPatientVarianceMutation();
  const saveIdent = () => {
    dispatch(setVarianceIndividual(varianceStart.individual));
    startVariance();
    dispatch(toggleInitiatePatientSearchDialog());
  };
  const command = new VarianceStartCommand(varianceStart);
  const startVariance = () => {
    initiate(command)
      .unwrap()
      .then((fullfilled) => {
        navigate("/varianceRequestDetail/" + fullfilled.id);
      });
      dispatch(setVarianceIndividual(DefaultVarianceRequest().individual));
  };

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <React.Fragment>
      <GridButton
        onClick={() => {
          setIsLoading(true);
          saveIdent();
        }}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Initiate"}
      </GridButton>
    </React.Fragment>
  );
}

export { VarianceInitiateCommand };
