import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";
import { useFindVarianceRequestQuery } from "../../../../../services/varianceService/variance-api";
import { CircularProgress, Typography, List, ListItem, ListItemText, ListItemIcon, Grid, Avatar, Divider, ListItemButton, ListItemSecondaryAction } from "@mui/material";
import { DefaultAssignedUser } from "../../../../../services/varianceService/model/varianceRequest/AssignedUser";
import { AccountCircle } from "@mui/icons-material";
import { VariancePendingApproveBtn } from "../commands/StatusChanges/VariancePendingApproveBtn";
import { IncidentUnAssignUserBtn } from "../commands/AssignUser/IncidentUnAssignUserBtn";

type AssignedStatusViewProps = {
  varianceId: string;
};

function AssignedStatusView({ varianceId }: AssignedStatusViewProps) {
  const profile = useMyProfileQuery();
  const [notify, setNotify] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { data: varianceRequest, isLoading } = useFindVarianceRequestQuery(varianceId);

  if (isLoading) {
    return <CircularProgress />;
  }

  const assignedUsers = varianceRequest?.assignedUsers || [DefaultAssignedUser()];
  const canUnAssign = profile.data?.role.includes("Quality Team") || profile.data?.role.includes("Admin")
  || profile.data?.claimsAccess.find((p) => p.code === "IncidentQualityTeam") !== undefined
  || profile.data?.claimsAccess.find((p) => p.code === "IncidentAdmin") !== undefined;

  return (
    <div>
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Grid item xs={8} spacing={8}>
          <List style={{ backgroundColor: "#f5f5f5" }}>
            {assignedUsers.length === 0 ? (
              <Typography variant="h6" align="center" style={{ padding: "20px" }}>
                No assigned users
              </Typography>
            ) : null}
            {assignedUsers.map((user, index) => (
              <React.Fragment key={user.upn}>
                <ListItem>              
                <Grid item xs={4}>
                  <ListItemIcon>
                    <Avatar style={{ backgroundColor: "#62BCBD" }}>
                      <AccountCircle />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                  
                    primary={user.displayName}
                    secondary={user.isClosed ? "Completed" : "Pending"}
                  />
                </Grid>
                  <Grid container alignItems="center">
                    {profile.data?.upn === user.upn && !user.isClosed && (
                      <Grid item>
                        <ListItemButton>
                          <VariancePendingApproveBtn
                            setNotify={setNotify}
                            setMessage={setMessage}
                          />
                        </ListItemButton>
                      </Grid>
                    )}
                    {canUnAssign && (
                      <Grid item>
                        <IncidentUnAssignUserBtn
                          profile={profile.data}
                          assignedUser={user}
                        />
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
                {index < assignedUsers.length - 1 && (
                  <Divider style={{ backgroundColor: "#62BCBD" }} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}

export { AssignedStatusView };
