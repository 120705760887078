import { CarveOutCombo } from "../../../../services/verifyService/model/verification/CarveoutCombo";
import { EstimatedProcedure } from "../../../../services/verifyService/model/verification/EstimatedProcedure";
import { outPatientLCodeCarveOuts } from "./OutPatientLCodeCarveOuts";

export class CarveOutRules {
  static Apply(carveOutCode: CarveOutCombo, procs: EstimatedProcedure[]): EstimatedProcedure[] {
    let addedProcs: EstimatedProcedure[] = [];

    // Ensure 63650 entered twice and add L8680 x16
    if (carveOutCode.CPTMatch === "63650" && carveOutCode.Code === "63650") {
      const proc63650Count = procs.filter((proc) => proc.code === "63650").length;
      const proc63650 = outPatientLCodeCarveOuts.find((carveOut) => carveOut.CPTMatch.includes("63650") && carveOut.Code === "63650");
      if (proc63650Count < 2) {
        addedProcs.push(...Array(2 - proc63650Count).fill(new EstimatedProcedure("63650", proc63650?.Description ?? "EstimatedProcedure 63650")));
      }
    }
    if (carveOutCode.CPTMatch === "63650" && carveOutCode.Code === "L8680") {
      const procL8680Count = procs.filter((proc) => proc.code === "L8680").length;
      const procL8680 = outPatientLCodeCarveOuts.find((carveOut) => carveOut.CPTMatch.includes("63650") && carveOut.Code === ("L8680"));
      if (procL8680Count < 16) {
        addedProcs.push(...Array(16 - procL8680Count).fill(new EstimatedProcedure("L8680", procL8680?.Description ?? "EstimatedProcedure L8680")));
      }
    }

    // Add L8687 for 63685
    if (carveOutCode.CPTMatch === "63685") {
      const procL8687Count = procs.filter((proc) => proc.code === "L8687").length;
      const procL8687 = outPatientLCodeCarveOuts.find((carveOut) => carveOut.CPTMatch.includes("63685") && carveOut.Code === "L8687");
      if (procL8687Count < 1) {
        addedProcs.push(new EstimatedProcedure("L8687", procL8687?.Description ?? "EstimatedProcedure L8687"));
      }
    }

    // Add L8688 and L8680 x4 for 64582
    if (carveOutCode.CPTMatch === "64582" && carveOutCode.Code === "L8688") {
      const procL8688Count = procs.filter((proc) => proc.code === "L8688").length;
      const procL8688 = outPatientLCodeCarveOuts.find((carveOut) => carveOut.CPTMatch.includes("64582") && carveOut.Code === "L8688");
      if (procL8688Count < 1) {
        addedProcs.push(new EstimatedProcedure("L8688", procL8688?.Description ?? "EstimatedProcedure L8688"));
      }
    }
    if (carveOutCode.CPTMatch === "64582" && carveOutCode.Code === "L8680") {
      const procL8680Count = procs.filter((proc) => proc.code === "L8680").length;
      const procL8680 = outPatientLCodeCarveOuts.find((carveOut) => carveOut.CPTMatch.includes("64582") && carveOut.Code === "L8680");
      if (procL8680Count < 4) {
        addedProcs.push(...Array(4 - procL8680Count).fill(new EstimatedProcedure("L8680", procL8680?.Description ?? "EstimatedProcedure L8680")));
      }
    }

    // Add L8614 for 69930
    if (carveOutCode.CPTMatch === "69930") {
      const procL8614Count = procs.filter((proc) => proc.code === "L8614").length;
      const procL8614 = outPatientLCodeCarveOuts.find((carveOut) => carveOut.CPTMatch.includes("69930") && carveOut.Code === "L8614");
      if (procL8614Count < 1) {
        addedProcs.push(new EstimatedProcedure("L8614", procL8614?.Description ?? "EstimatedProcedure L8614"));
      }
    }

    return addedProcs;
  }
}