import React from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { VarianceDetailsCommands } from "../features/details/commands/StatusChanges/VarianceDetailsCommands";
import { VarianceHeader } from "../features/details/Header/VarianceHeader";
import { IndividualDetails } from "../features/details/IndividualDetails";
import { setTabStripPosition } from "../../../app/slices/VarianceModuleSlice";
import {
  useFindVarianceRequestQuery,
  useVarianceSaveMutation,
} from "../../../services/varianceService/variance-api";
import { Loading } from "../../shared";
import { VariancePostCommand } from "../../../services/varianceService/commands/VariancePostCommand";
import { useMyProfileQuery } from "../../../services/profileService/profile-api";
import { IncidentComments } from "../features/details/comments/IncidentComments";
import { Table } from "@mui/material";
import { IncidentDescription } from "../features/details/description/IncidentDescription";
import { DirectorResponse } from "../features/details/directorResponse/DirectorResponse";
import { ImmediateFollowUp } from "../features/details/followup/FollowUp";
import { WitnessSection } from "../features/details/witnesses/WitnessSection";
import { Severity } from "../features/details/severity/Severity";
import { VarianceDocumentList } from "../features/details/commands/Documents/VarianceDocumentLIst";
import { AssignedStatusView } from "../features/details/assignedUsers/AssignedStatusView";
import { IncidentTab } from "../features/details/IncidentTab/IncidentTab";

function VarianceRequestDetail() {
  const dispatch = useAppDispatch();
  const [varianceSave, status] = useVarianceSaveMutation();
  const { Id } = useParams();
  const { isLoading } = useFindVarianceRequestQuery(Id!);
  const tabIndex = useAppSelector(
    (state) => state.varianceModule.TabStripPosition
  );
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const toggleTab = (e: TabStripSelectEventArguments) =>
    dispatch(setTabStripPosition(e.selected));
const myProfile = useMyProfileQuery();
const canSave = myProfile.data?.role === "Admin";
  const handleSave = () => {
    if(canSave){
    let command = new VariancePostCommand(incident);
    varianceSave(command)
      .unwrap()
      .then(() => {});
    }
  };
  const canEdit =():boolean=>{
    if(incident.status === "Complete"){return false;}
    if(myProfile.data?.role === 'Admin'){return true;}
    if(myProfile.data?.role === 'Quality Team'){return true;}
    if(myProfile.data?.claimsAccess.find(p => p.code === 'IncidentQualityTeam') != undefined){return true;}
    if(myProfile.data?.claimsAccess.find(p => p.code === 'IncidentEdit' && incident.status === "Started") != undefined){return true;}
    if(incident?.assignedUsers && incident?.assignedUsers.find(p => p.upn === myProfile.data?.upn)){return true;}
    return false;
  }
  const isDirectorTabEnabled = (): boolean => {
    if (
      myProfile.data?.role === "Admin" &&
      incident.incidentType !== "Started"
    ) {
      return true;
    }
    if (
      myProfile.data?.role === "Quality Team" &&
      incident.incidentType !== "Started"
    ) {
      return true;
    }
    if (
      myProfile.data?.claimsAccess!.find((p) => p.code === "IncidentAdmin") !=
        undefined &&
      incident.incidentType !== "Started"
    ) {
      return true;
    }
    if (
      myProfile.data?.claimsAccess!.find(
        (p) => p.code === "IncidentQualityTeam"
      ) != undefined &&
      incident.incidentType !== "Started"
    ) {
      return true;
    }
    if (
      incident?.assignedUsers &&
      incident?.assignedUsers!.find((p) => p.upn === myProfile.data?.upn) &&
      incident.status === "Assigned"
    ) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      {isLoading && (
        <h5>
          <Loading />
        </h5>
      )}
      {!isLoading && (
        <div>
          <VarianceDetailsCommands />
          <VarianceHeader />
          <IndividualDetails />
          <TabStrip
            selected={tabIndex}
            onSelect={(e) => {
              toggleTab(e);
              {
                handleSave();
              }
            }}
          >
            <TabStripTab title="Incident">
              <IncidentTab incident={incident} canEdit={canEdit} />
            </TabStripTab>
            <TabStripTab title="Severity">
              <Table />
              <Severity canEdit={canEdit()}/>
            </TabStripTab>
            <TabStripTab title="Description">
              <Table />
              <IncidentDescription />
            </TabStripTab>
            <TabStripTab title="Witnesses">
              <Table />
              <WitnessSection />
            </TabStripTab>
            <TabStripTab title="Follow Up">
              <Table />
              <ImmediateFollowUp />
            </TabStripTab>
            {isDirectorTabEnabled() && (
              <TabStripTab title="Director Response">
                <Table />
                <DirectorResponse />
              </TabStripTab>
            )}
            <TabStripTab title="Comments">
              <Table />
              <IncidentComments />
            </TabStripTab>
          <TabStripTab title="Documents">
              <Table />
              <VarianceDocumentList />
            </TabStripTab>
          <TabStripTab title="Assigned Users">
              <Table />
              <AssignedStatusView varianceId={Id!} />
            </TabStripTab>
          </TabStrip>
        </div>
      )}
    </React.Fragment>
  );
}

export { VarianceRequestDetail };
