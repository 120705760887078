export const DelayOfCareOptions = [
    {
        description: "Orders not followed",
        name: "OrdersNotFollowed",
    },
    {
        description: "Treatment delayed related to equipment",
        name: "TreatmentDelayedEquipment",
    },
    {
        description: "Treatment delayed to no orders present",
        name: "TreatmentDelayedNoOrders",
    },
    {
        description: "Treatment delayed related to patient",
        name: "TreatmentDelayedPatient",
    },
    {
        description: "Treatment delayed related to pending labs/test results",
        name: "TreatmentDelayedLabs",
    },
    {
        description: "Treatment delayed related to physician",
        name: "TreatmentDelayedPhysician",
    },
    {
        description: "Treatment delayed related to staff",
        name: "TreatmentDelayedStaff",
    },
    ];