export const MedicationEventOptions = [
    { id: '1', name: 'ADMIN', description: 'Administration Technique Incorrect' },
    { id: '2', name: 'ALLERGY', description: 'Allergy Unknown to Patient' },
    { id: '3', name: 'CONTRA', description: 'Contraindicated' },
    { id: '4', name: 'DOSEEXTRA', description: 'Dose Incorrect – Extra Dose' },
    { id: '5', name: 'DOSEOMIT', description: 'Dose Incorrect- Omitted Dose' },
    { id: '6', name: 'DOSEWRONG', description: 'Dose Incorrect- Wrong Dose' },
    { id: '7', name: 'MEDUNAVAIL', description: 'Medication Unavailable' },
    { id: '8', name: 'MEDEXPIRED', description: 'Medication-Expired' },
    { id: '9', name: 'MEDLABEL', description: 'Medication Labeling Issue' },
    { id: '10', name: 'MEDRECALL', description: 'Medication- Recalled' },
    { id: '11', name: 'MEDNOTORDERED', description: 'Medication Not Ordered' },
    { id: '12', name: 'NARCOTICCOUNT', description: 'Narcotic Count Incorrect' },
    { id: '13', name: 'NARCOTICWASTE', description: 'Narcotic Waste not documented' },
    { id: '14', name: 'ORDER', description: 'Order Incorrect' },
    { id: '15', name: 'MEDDURATION', description: 'Medication Timing – Duration Incorrect' },
    { id: '16', name: 'MEDFREQ', description: 'Medication Timing – Frequency Wrong' },
    { id: '17', name: 'MEDTIMING', description: 'Medication Timing Issue' },
    { id: '18', name: 'MONITOR', description: 'Monitoring Issue' },
    { id: '19', name: 'PAIN', description: 'Pain Management Inadequate' },
    { id: '20', name: 'REFUSED', description: 'Patient Refused Medication' },
    { id: '21', name: 'RATE', description: 'Rate Incorrect' },
    { id: '22', name: 'ROUTE', description: 'Route Incorrect' },
    { id: '23', name: 'SITE', description: 'Site Incorrect' },
    { id: '24', name: 'STORAGE', description: 'Storage Incorrect' },
    { id: '25', name: 'STRENGTH', description: 'Strength/Concentration Issue' },
    { id: '26', name: 'WRONGPATIENT', description: 'Wrong Patient' },
    { id: '27', name: 'NARCOTICREMOVAL', description: 'Narcotics removal-to-administration exceeding 30 minutes' },
    { id: '28', name: 'INCOMPLETE', description: 'Incomplete or missing medication administration or waste' },
    { id: '29', name: 'COADMIN', description: 'Co-administration of PO and IV controlled substances' },
    { id: '30', name: 'CONTROLLEDDOCUMENTATION', description: 'Controlled substance documentation errors' },
    { id: '31', name: 'EARLYDOSING', description: 'Early dosing of opioids' },
    { id: '32', name: 'OPIOIDSPAINSACLE', description: 'Opioids administered with missing or incorrect pain scales' },
    { id: '33', name: 'NURSEREMOVING', description: 'Nurses removing controlled substances for other nurses to administer' },
    { id: '34', name: 'OTHER', description: 'Other' },
];
