export const Departments = [
    {centerCode: "A98", department: "Administration"}
    ,{centerCode: "A98", department: "BioMedical"}
    ,{centerCode: "A98", department: "Café"}
    ,{centerCode: "A98", department: "Case Management"}
    ,{centerCode: "A98", department: "Dietary (Kitchen)"}
    ,{centerCode: "A98", department: "Emergency Treatment Area"}
    ,{centerCode: "A98", department: "Facilities"}
    ,{centerCode: "A98", department: "HIM (Health Information Management)"}
    ,{centerCode: "A98", department: "Human Resources"}
    ,{centerCode: "A98", department: "Infection Prevention"}
    ,{centerCode: "A98", department: "Laboratory"}
    ,{centerCode: "A98", department: "Materials Management"}
    ,{centerCode: "A98", department: "Medical Facilities Holdings"}
    ,{centerCode: "A98", department: "Operating Room"}
    ,{centerCode: "A98", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "A98", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "A98", department: "Patient Access/Registration"}
    ,{centerCode: "A98", department: "Pharmacy"}
    ,{centerCode: "A98", department: "Preoperative"}
    ,{centerCode: "A98", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "A98", department: "Quality"}
    ,{centerCode: "A98", department: "Radiology"}
    ,{centerCode: "A98", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "A98", department: "Lobby"}
    ,{centerCode: "A98", department: "Other"}

    ,{centerCode: "Y16", department: "Administration"}
    ,{centerCode: "Y16", department: "BioMedical"}
    ,{centerCode: "Y16", department: "Café"}
    ,{centerCode: "Y16", department: "Case Management"}
    ,{centerCode: "Y16", department: "Dietary (Kitchen)"}
    ,{centerCode: "Y16", department: "Emergency Treatment Area"}
    ,{centerCode: "Y16", department: "Facilities"}
    ,{centerCode: "Y16", department: "HIM (Health Information Management)"}
    ,{centerCode: "Y16", department: "Human Resources"}
    ,{centerCode: "Y16", department: "Laboratory"}
    ,{centerCode: "Y16", department: "Materials Management"}
    ,{centerCode: "Y16", department: "Medical Facilities Holdings"}
    ,{centerCode: "Y16", department: "Operating Room"}
    ,{centerCode: "Y16", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "Y16", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "Y16", department: "Patient Access/Registration"}
    ,{centerCode: "Y16", department: "Pharmacy"}
    ,{centerCode: "Y16", department: "Preoperative"}
    ,{centerCode: "Y16", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "Y16", department: "Quality/Infection Prevention"}
    ,{centerCode: "Y16", department: "Radiology"}
    ,{centerCode: "Y16", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "Y16", department: "Lobby"}
    ,{centerCode: "Y16", department: "Other"}

    ,{centerCode: "MBM", department: "Administration"}
    ,{centerCode: "MBM", department: "Café"}
    ,{centerCode: "MBM", department: "Case Management"}
    ,{centerCode: "MBM", department: "Dietary (Kitchen)"}
    ,{centerCode: "MBM", department: "Emergency Treatment Area"}
    ,{centerCode: "MBM", department: "Facilities"}
    ,{centerCode: "MBM", department: "HIM (Health Information Management)"}
    ,{centerCode: "MBM", department: "Human Resources"}
    ,{centerCode: "MBM", department: "ICU (Intensive Care Unit)"}
    ,{centerCode: "MBM", department: "Laboratory"}
    ,{centerCode: "MBM", department: "Materials Management"}
    ,{centerCode: "MBM", department: "Operating Room"}
    ,{centerCode: "MBM", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "MBM", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "MBM", department: "Patient Access/Registration"}
    ,{centerCode: "MBM", department: "Pharmacy"}    
    ,{centerCode: "MBM", department: "Physical Therapy (inpatient)"}
    ,{centerCode: "MBM", department: "Physical Therapy Center Allen"}
    ,{centerCode: "MBM", department: "Physical Therapy Center Frisco"}
    ,{centerCode: "MBM", department: "Physical Therapy Center Plano"}
    ,{centerCode: "MBM", department: "Physical Therapy Center Richardson"}
    ,{centerCode: "MBM", department: "Preoperative"}
    ,{centerCode: "MBM", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "MBM", department: "Quality/Infection Prevention"}
    ,{centerCode: "MBM", department: "Radiology"}
    ,{centerCode: "MBM", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "MBM", department: "Lobby"}
    ,{centerCode: "MBM", department: "Other"}

    ,{centerCode: "LG9", department: "Administration"}
    ,{centerCode: "LG9", department: "Café"}
    ,{centerCode: "LG9", department: "Case Management"}
    ,{centerCode: "LG9", department: "Dietary (Kitchen)"}
    ,{centerCode: "LG9", department: "Emergency Treatment Area"}
    ,{centerCode: "LG9", department: "Facilities"}
    ,{centerCode: "LG9", department: "HIM (Health Information Management)"}
    ,{centerCode: "LG9", department: "Human Resources"}
    ,{centerCode: "LG9", department: "ICU (Intensive Care Unit)"}
    ,{centerCode: "LG9", department: "Laboratory"}
    ,{centerCode: "LG9", department: "Materials Management"}
    ,{centerCode: "LG9", department: "Operating Room"}
    ,{centerCode: "LG9", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "LG9", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "LG9", department: "Patient Access/Registration"}
    ,{centerCode: "LG9", department: "Pharmacy"}
    ,{centerCode: "LG9", department: "Preoperative"}
    ,{centerCode: "LG9", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "LG9", department: "Quality/Infection Prevention"}
    ,{centerCode: "LG9", department: "Radiology"}
    ,{centerCode: "LG9", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "LG9", department: "Lobby"}
    ,{centerCode: "LG9", department: "Other"}

    ,{centerCode: "0F0", department: "Laboratory"}
    ,{centerCode: "0F0", department: "Materials Management"}
    ,{centerCode: "0F0", department: "Operating Room"}
    ,{centerCode: "0F0", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "0F0", department: "Patient Access/Registration"}
    ,{centerCode: "0F0", department: "Pharmacy"}
    ,{centerCode: "0F0", department: "Preoperative"}
    ,{centerCode: "0F0", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "0F0", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "0F0", department: "Lobby"}
    ,{centerCode: "0F0", department: "Other"}

    ,{centerCode: "LRY", department: "Laboratory"}
    ,{centerCode: "LRY", department: "Materials Management"}
    ,{centerCode: "LRY", department: "Operating Room"}
    ,{centerCode: "LRY", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "LRY", department: "Patient Access/Registration"}
    ,{centerCode: "LRY", department: "Pharmacy"}
    ,{centerCode: "LRY", department: "Preoperative"}
    ,{centerCode: "LRY", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "LRY", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "LRY", department: "Lobby"}
    ,{centerCode: "LRY", department: "Other"}

    ,{centerCode: "H01", department: "Laboratory"}
    ,{centerCode: "H01", department: "Materials Management"}
    ,{centerCode: "H01", department: "Operating Room"}
    ,{centerCode: "H01", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "H01", department: "Patient Access/Registration"}
    ,{centerCode: "H01", department: "Pharmacy"}
    ,{centerCode: "H01", department: "Preoperative"}
    ,{centerCode: "H01", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "H01", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "H01", department: "Lobby"}
    ,{centerCode: "H01", department: "Other"}

    ,{centerCode: "S94", department: "Laboratory"}
    ,{centerCode: "S94", department: "Materials Management"}
    ,{centerCode: "S94", department: "Operating Room"}
    ,{centerCode: "S94", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "S94", department: "Patient Access/Registration"}
    ,{centerCode: "S94", department: "Pharmacy"}
    ,{centerCode: "S94", department: "Preoperative"}
    ,{centerCode: "S94", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "S94", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "S94", department: "Lobby"}
    ,{centerCode: "S94", department: "Other"}

    ,{centerCode: "Z6P", department: "Laboratory"}
    ,{centerCode: "Z6P", department: "Materials Management"}
    ,{centerCode: "Z6P", department: "Operating Room"}
    ,{centerCode: "Z6P", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "Z6P", department: "Patient Access/Registration"}
    ,{centerCode: "Z6P", department: "Pharmacy"}
    ,{centerCode: "Z6P", department: "Preoperative"}
    ,{centerCode: "Z6P", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "Z6P", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "Z6P", department: "Lobby"}
    ,{centerCode: "Z6P", department: "Other"}

    ,{centerCode: "IEB", department: "Laboratory"}
    ,{centerCode: "IEB", department: "Materials Management"}
    ,{centerCode: "IEB", department: "Operating Room"}
    ,{centerCode: "IEB", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "IEB", department: "Patient Access/Registration"}
    ,{centerCode: "IEB", department: "Pharmacy"}
    ,{centerCode: "IEB", department: "Preoperative"}
    ,{centerCode: "IEB", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "IEB", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "IEB", department: "Lobby"}
    ,{centerCode: "IEB", department: "Other"}

    ,{centerCode: "4TQ", department: "Laboratory"}
    ,{centerCode: "4TQ", department: "Materials Management"}
    ,{centerCode: "4TQ", department: "Operating Room"}
    ,{centerCode: "4TQ", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "4TQ", department: "Patient Access/Registration"}
    ,{centerCode: "4TQ", department: "Pharmacy"}
    ,{centerCode: "4TQ", department: "Preoperative"}
    ,{centerCode: "4TQ", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "4TQ", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "4TQ", department: "Lobby"}
    ,{centerCode: "4TQ", department: "Other"}

    ,{centerCode: "V3P", department: "Laboratory"}
    ,{centerCode: "V3P", department: "Materials Management"}
    ,{centerCode: "V3P", department: "Operating Room"}
    ,{centerCode: "V3P", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "V3P", department: "Patient Access/Registration"}
    ,{centerCode: "V3P", department: "Pharmacy"}
    ,{centerCode: "V3P", department: "Preoperative"}
    ,{centerCode: "V3P", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "V3P", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "V3P", department: "Lobby"}
    ,{centerCode: "V3P", department: "Other"}

    ,{centerCode: "P6Q", department: "Laboratory"}
    ,{centerCode: "P6Q", department: "Materials Management"}
    ,{centerCode: "P6Q", department: "Operating Room"}
    ,{centerCode: "P6Q", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "P6Q", department: "Patient Access/Registration"}
    ,{centerCode: "P6Q", department: "Pharmacy"}
    ,{centerCode: "P6Q", department: "Preoperative"}
    ,{centerCode: "P6Q", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "P6Q", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "P6Q", department: "Lobby"}
    ,{centerCode: "P6Q", department: "Other"}

    ,{centerCode: "A9P", department: "Laboratory"}
    ,{centerCode: "A9P", department: "Materials Management"}
    ,{centerCode: "A9P", department: "Operating Room"}
    ,{centerCode: "A9P", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "A9P", department: "Patient Access/Registration"}
    ,{centerCode: "A9P", department: "Pharmacy"}
    ,{centerCode: "A9P", department: "Preoperative"}
    ,{centerCode: "A9P", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "A9P", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "A9P", department: "Lobby"}
    ,{centerCode: "A9P", department: "Other"}

    ,{centerCode: "F9S", department: "Laboratory"}
    ,{centerCode: "F9S", department: "Materials Management"}
    ,{centerCode: "F9S", department: "Operating Room"}
    ,{centerCode: "F9S", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "F9S", department: "Patient Access/Registration"}
    ,{centerCode: "F9S", department: "Pharmacy"}
    ,{centerCode: "F9S", department: "Preoperative"}
    ,{centerCode: "F9S", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "F9S", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "F9S", department: "Lobby"}
    ,{centerCode: "F9S", department: "Other"}

    ,{centerCode: "RCH", department: "Laboratory"}
    ,{centerCode: "RCH", department: "Materials Management"}
    ,{centerCode: "RCH", department: "Operating Room"}
    ,{centerCode: "RCH", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "RCH", department: "Patient Access/Registration"}
    ,{centerCode: "RCH", department: "Pharmacy"}
    ,{centerCode: "RCH", department: "Preoperative"}
    ,{centerCode: "RCH", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "RCH", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "RCH", department: "Lobby"}
    ,{centerCode: "RCH", department: "Other"}

    ,{centerCode: "RX1", department: "Laboratory"}
    ,{centerCode: "RX1", department: "Materials Management"}
    ,{centerCode: "RX1", department: "Operating Room"}
    ,{centerCode: "RX1", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "RX1", department: "Patient Access/Registration"}
    ,{centerCode: "RX1", department: "Pharmacy"}
    ,{centerCode: "RX1", department: "Preoperative"}
    ,{centerCode: "RX1", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "RX1", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "RX1", department: "Lobby"}
    ,{centerCode: "RX1", department: "Other"}
    
    ,{centerCode: "CORP", department: "Human Resources"}
    ,{centerCode: "CORP", department: "Clinical Operations"}
    ,{centerCode: "CORP", department: "Facilities"}
    ,{centerCode: "CORP", department: "Compliance"}
    ,{centerCode: "CORP", department: "Accounting"}
    ,{centerCode: "CORP", department: "Finance"}
    ,{centerCode: "CORP", department: "Materials Management"}
    ,{centerCode: "CORP", department: "Information Technology"}
    ,{centerCode: "CORP", department: "Managed Care"}
    ,{centerCode: "CORP", department: "Revenue Cycle Management"}
    ,{centerCode: "CORP", department: "Payroll"}
    ,{centerCode: "CORP", department: "Legal Department"}
    ,{centerCode: "CORP", department: "Communications and Branding"}
    ,{centerCode: "CORP", department: "Business Development"}
    ,{centerCode: "CORP", department: "Lobby"}
    ,{centerCode: "CORP", department: "Other"}

    ,{centerCode: "BCN", department: "Administration"}
    ,{centerCode: "BCN", department: "Café"}
    ,{centerCode: "BCN", department: "Case Management"}
    ,{centerCode: "BCN", department: "Dietary (Kitchen)"}
    ,{centerCode: "BCN", department: "Emergency Treatment Area"}
    ,{centerCode: "BCN", department: "Facilities"}
    ,{centerCode: "BCN", department: "HIM (Health Information Management)"}
    ,{centerCode: "BCN", department: "Human Resources"}
    ,{centerCode: "BCN", department: "ICU (Intensive Care Unit)"}
    ,{centerCode: "BCN", department: "Laboratory"}
    ,{centerCode: "BCN", department: "Materials Management"}
    ,{centerCode: "BCN", department: "Operating Room"}
    ,{centerCode: "BCN", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "BCN", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "BCN", department: "Patient Access/Registration"}
    ,{centerCode: "BCN", department: "Pharmacy"}    
    ,{centerCode: "BCN", department: "Physical Therapy (inpatient)"}
    ,{centerCode: "BCN", department: "Physical Therapy Center Allen"}
    ,{centerCode: "BCN", department: "Physical Therapy Center Frisco"}
    ,{centerCode: "BCN", department: "Physical Therapy Center Plano"}
    ,{centerCode: "BCN", department: "Physical Therapy Center Richardson"}
    ,{centerCode: "BCN", department: "Preoperative"}
    ,{centerCode: "BCN", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "BCN", department: "Quality/Infection Prevention"}
    ,{centerCode: "BCN", department: "Radiology"}
    ,{centerCode: "BCN", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "BCN", department: "Lobby"}
    ,{centerCode: "BCN", department: "Other"}

    ,{centerCode: "BKN", department: "Administration"}
    ,{centerCode: "BKN", department: "Café"}
    ,{centerCode: "BKN", department: "Case Management"}
    ,{centerCode: "BKN", department: "Dietary (Kitchen)"}
    ,{centerCode: "BKN", department: "Emergency Treatment Area"}
    ,{centerCode: "BKN", department: "Facilities"}
    ,{centerCode: "BKN", department: "HIM (Health Information Management)"}
    ,{centerCode: "BKN", department: "Human Resources"}
    ,{centerCode: "BKN", department: "ICU (Intensive Care Unit)"}
    ,{centerCode: "BKN", department: "Laboratory"}
    ,{centerCode: "BKN", department: "Materials Management"}
    ,{centerCode: "BKN", department: "Operating Room"}
    ,{centerCode: "BKN", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "BKN", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "BKN", department: "Patient Access/Registration"}
    ,{centerCode: "BKN", department: "Pharmacy"}    
    ,{centerCode: "BKN", department: "Physical Therapy (inpatient)"}
    ,{centerCode: "BKN", department: "Physical Therapy Center Allen"}
    ,{centerCode: "BKN", department: "Physical Therapy Center Frisco"}
    ,{centerCode: "BKN", department: "Physical Therapy Center Plano"}
    ,{centerCode: "BKN", department: "Physical Therapy Center Richardson"}
    ,{centerCode: "BKN", department: "Preoperative"}
    ,{centerCode: "BKN", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "BKN", department: "Quality/Infection Prevention"}
    ,{centerCode: "BKN", department: "Radiology"}
    ,{centerCode: "BKN", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "BKN", department: "Lobby"}
    ,{centerCode: "BKN", department: "Other"}

    ,{centerCode: "9LN", department: "Administration"}
    ,{centerCode: "9LN", department: "Café"}
    ,{centerCode: "9LN", department: "Case Management"}
    ,{centerCode: "9LN", department: "Dietary (Kitchen)"}
    ,{centerCode: "9LN", department: "Emergency Treatment Area"}
    ,{centerCode: "9LN", department: "Facilities"}
    ,{centerCode: "9LN", department: "HIM (Health Information Management)"}
    ,{centerCode: "9LN", department: "Human Resources"}
    ,{centerCode: "9LN", department: "ICU (Intensive Care Unit)"}
    ,{centerCode: "9LN", department: "Laboratory"}
    ,{centerCode: "9LN", department: "Materials Management"}
    ,{centerCode: "9LN", department: "Operating Room"}
    ,{centerCode: "9LN", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "9LN", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "9LN", department: "Patient Access/Registration"}
    ,{centerCode: "9LN", department: "Pharmacy"}    
    ,{centerCode: "9LN", department: "Physical Therapy (inpatient)"}
    ,{centerCode: "9LN", department: "Physical Therapy Center Allen"}
    ,{centerCode: "9LN", department: "Physical Therapy Center Frisco"}
    ,{centerCode: "9LN", department: "Physical Therapy Center Plano"}
    ,{centerCode: "9LN", department: "Physical Therapy Center Richardson"}
    ,{centerCode: "9LN", department: "Preoperative"}
    ,{centerCode: "9LN", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "9LN", department: "Quality/Infection Prevention"}
    ,{centerCode: "9LN", department: "Radiology"}
    ,{centerCode: "9LN", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "9LN", department: "Lobby"}
    ,{centerCode: "9LN", department: "Other"}

    ,{centerCode: "M3S", department: "Administration"}
    ,{centerCode: "M3S", department: "Café"}
    ,{centerCode: "M3S", department: "Case Management"}
    ,{centerCode: "M3S", department: "Dietary (Kitchen)"}
    ,{centerCode: "M3S", department: "Emergency Treatment Area"}
    ,{centerCode: "M3S", department: "Facilities"}
    ,{centerCode: "M3S", department: "HIM (Health Information Management)"}
    ,{centerCode: "M3S", department: "Human Resources"}
    ,{centerCode: "M3S", department: "ICU (Intensive Care Unit)"}
    ,{centerCode: "M3S", department: "Laboratory"}
    ,{centerCode: "M3S", department: "Materials Management"}
    ,{centerCode: "M3S", department: "Operating Room"}
    ,{centerCode: "M3S", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "M3S", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "M3S", department: "Patient Access/Registration"}
    ,{centerCode: "M3S", department: "Pharmacy"}    
    ,{centerCode: "M3S", department: "Physical Therapy (inpatient)"}
    ,{centerCode: "M3S", department: "Physical Therapy Center Allen"}
    ,{centerCode: "M3S", department: "Physical Therapy Center Frisco"}
    ,{centerCode: "M3S", department: "Physical Therapy Center Plano"}
    ,{centerCode: "M3S", department: "Physical Therapy Center Richardson"}
    ,{centerCode: "M3S", department: "Preoperative"}
    ,{centerCode: "M3S", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "M3S", department: "Quality/Infection Prevention"}
    ,{centerCode: "M3S", department: "Radiology"}
    ,{centerCode: "M3S", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "M3S", department: "Lobby"}
    ,{centerCode: "M3S", department: "Other"}

    ,{centerCode: "93N", department: "Administration"}
    ,{centerCode: "93N", department: "Café"}
    ,{centerCode: "93N", department: "Case Management"}
    ,{centerCode: "93N", department: "Dietary (Kitchen)"}
    ,{centerCode: "93N", department: "Emergency Treatment Area"}
    ,{centerCode: "93N", department: "Facilities"}
    ,{centerCode: "93N", department: "HIM (Health Information Management)"}
    ,{centerCode: "93N", department: "Human Resources"}
    ,{centerCode: "93N", department: "ICU (Intensive Care Unit)"}
    ,{centerCode: "93N", department: "Laboratory"}
    ,{centerCode: "93N", department: "Materials Management"}
    ,{centerCode: "93N", department: "Operating Room"}
    ,{centerCode: "93N", department: "PACU (Post Anesthesia Care Unit)"}
    ,{centerCode: "93N", department: "PAT (Pre-Admit Testing)"}
    ,{centerCode: "93N", department: "Patient Access/Registration"}
    ,{centerCode: "93N", department: "Pharmacy"}    
    ,{centerCode: "93N", department: "Physical Therapy (inpatient)"}
    ,{centerCode: "93N", department: "Physical Therapy Center Allen"}
    ,{centerCode: "93N", department: "Physical Therapy Center Frisco"}
    ,{centerCode: "93N", department: "Physical Therapy Center Plano"}
    ,{centerCode: "93N", department: "Physical Therapy Center Richardson"}
    ,{centerCode: "93N", department: "Preoperative"}
    ,{centerCode: "93N", department: "PSU (Post Surgical Unit)"}
    ,{centerCode: "93N", department: "Quality/Infection Prevention"}
    ,{centerCode: "93N", department: "Radiology"}
    ,{centerCode: "93N", department: "SPD (Sterile Processing Department)"}
    ,{centerCode: "93N", department: "Lobby"}
    ,{centerCode: "93N", department: "Other"}
];