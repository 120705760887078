import { Button } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { EstimatedProcedure } from "../../../../../../services/verifyService/model/verification/EstimatedProcedure";
import { addProcedure, resetCarveOutCombo,  
} from "../../../../../../services/verifyService/model/verification/VerificationSlice";
import { CarveOutRules } from "../../../../specs/carveouts/CarveOutRules";
import { forEach } from "lodash";
import { toggleOutPatientLCodeMatching } from "../../../../../../app/slices/VerificationModuleSlice";

function ApplyCarveOutCodes() {
  var dispatch = useAppDispatch();
  const verification = useAppSelector(
    (state) => state.verification.verification
  );
  const add = (code: string, description: string) => {
    var estimatedProc = new EstimatedProcedure(code, description);
    dispatch(addProcedure(estimatedProc));
  };
  const handleSave = () => {
    dispatch(toggleOutPatientLCodeMatching());
    verification.carveOutCombos.forEach((combo) => {
      let carveOuts = CarveOutRules.Apply(combo, verification.procedures);
      forEach(carveOuts, (proc) => {
        if (proc?.code && proc.description) {
          add(proc.code, proc.description);
        }
      });
    });
    dispatch(resetCarveOutCombo());
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={() => {
          handleSave();
        }}
      >
        Apply
      </Button>
    </React.Fragment>
  );
}

export { ApplyCarveOutCodes };
